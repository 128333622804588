


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class icono_chat extends Vue {
  @Prop({ default: 0 }) number_of_mesajes!: number;
}
